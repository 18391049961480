export default function createAlertUrl(notification) {
  if (!notification || !notification.alert || !notification.alert.data) {
    return '/'
  }

  const { type, data } = notification.alert
  const { id, tab } = data

  switch (type) {
    case 'CLIENT_PRODUCT_ACTIVITY':
    case 'CLIENT_PRODUCT_COMMENT':
      return `/datacollection/requests/${data.product_request_id}/${tab ||
        'history'}`
    case 'DATA_COLLECTION_PRODUCT_REQUEST':
      return `/datacollection/requests/${id}/${tab || 'properties'}`
    case 'DATA_COLLECTION_EFFORT':
    case 'DATA_COLLECTION_EFFORT_DUE_DATE':
      return `/datacollection/efforts/${id}/${tab || 'summary'}`
    case 'MACHINE_READABLE_FILE_SUBMIT_REQUEST':
      return `/harvesting/machinereadablefilemaps/${data.map_id || id}/history`
    case 'NETWORK_MAPPING':
      return `/dataprocessing/networkmappings/${id}/${tab || 'properties'}`
    case 'PROJECT_MAPPING':
      return `/dataprocessing/projectmappings/${id}/${tab || 'properties'}`
    case 'CLIENT_PRODUCT':
      return `/datacollection/products/${id}/${tab || 'properties'}`
    case 'DCE_GENERATION_TRANSACTION':
      return `/datacollection/efforts/${
        data.data_collection_effort_id
      }/generations/${data.generation_id}`
    case 'PRODUCT_REQUEST_RECEIVED':
      return `/datacollection/requests/${data.request_id}/properties`
    case 'COMMENT_MODERATION':
      return '/moderatedComments/new'
    case 'NEXT_ACTION_REMINDER':
      return `/datacollection/products/${
        data.client_product_id
      }/requests/${id}/properties`
    case 'NEXT_ACTION_REMINDER_MEDICARE_ADVANTAGE':
      return { pathname: "/datacollection/products", query: { type: "in_progress_ops" } }
    case 'CARRIER_APPROVAL_REQUEST_COMMENT':
      return `/dataprocessing/carrierapprovalrequests/${data.request_id}`
    default:
      return '/'
  }
}
