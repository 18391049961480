import React from 'react'
import { node, string, func, oneOfType } from 'prop-types'
import { Field } from 'redux-form'
import { withHandlers } from 'recompose'
import ValidatedField from './ValidatedField'

export const renderComponent = props => field => {
  const {
    name,
    component: Component,
    type,
    value,
    placement,
    /* eslint-disable no-unused-vars */
    normalize,
    /* eslint-enable no-unused-vars */
    ...rest
  } = props

  const childProps = {
    name,
    disabled: field.meta.submitting,
    type
  }

  if (Component === 'input' && (type === 'radio' || type === 'checkbox')) {
    childProps.checked = value === field.input.value
    field.input.value = value
  }

  return (
    <ValidatedField name={name} field={field} placement={placement}>
      <Component {...childProps} {...field.input} {...rest} />
    </ValidatedField>
  )
}

/*
  Wrapper around redux-form Field for components that are input, select, or textarea with validation included.
*/
export function InputField(props) {
  return (
    <Field
      name={props.name}
      normalize={props.normalize}
      component={props.renderComponent}
    />
  )
}

export default withHandlers({ renderComponent })(InputField)

InputField.propTypes = {
  name: string.isRequired,
  component: oneOfType([string, node, func]).isRequired,
}

InputField.defaultProps = {
  placement: 'right',
}
