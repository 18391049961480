import asyncComponent from '../AsyncComponent'

export const UserList = asyncComponent(() =>
  import(/* webpackChunkName: "Users" */ './UserList')
)

export const UserDetail = asyncComponent(() =>
  import(/* webpackChunkName: "Users" */ './UserDetail')
)

export const UserAddEditForm = asyncComponent(() =>
  import(/* webpackChunkName: "Users" */ './UserAddEditForm')
)

export const UserFeatures = asyncComponent(() =>
  import(/* webpackChunkName: "Users" */ './UserFeatures')
)
