import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import bot from '../bot.png'
import { useNotifications } from '../../hooks/useNotifications'

AlertItem.propTypes = {
  notification: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  closeDropdown: PropTypes.func.isRequired
}

AlertItem.defaultProps = {}

export default function AlertItem(props) {
  const {
    notification,
    type,
    closeDropdown,
    ...rest
  } = props

  const {
    id,
    hasViewed,
    createdAt,
    alert: {
      systemGenerated,
      gravatarUrl,
      displayName,
      message,
      isImportant
    } = {}
  } = notification

  const {
    notificationsQueries: { deleteNotificationMutation: [deleteNotification, { loading: deleteLoading }] },
    viewNotificationTarget
  } = useNotifications()

  const onClickViewNotification = () => {
    viewNotificationTarget(notification)
    closeDropdown()
  }

  const onClickDeleteNotification = () => deleteNotification({ variables: { id: id } })

  const itemClasses = classNames({
    'list-group-item': true,
    'list-group-item-danger': !hasViewed && type === "warnings",
    'list-group-item-warning': !hasViewed && type === "messages",
    'list-group-item-info': !hasViewed && type === "comments"
  })

  const textClasses = classNames({
    'text-danger': !hasViewed && type === "warnings",
    'text-warning': !hasViewed && type === "messages",
    'text-info': !hasViewed && type === "comments"
  })

  return (
    <li {...rest} className={itemClasses}>
      <div className="notification">
        <div className="pull-right">
          <form>
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={onClickDeleteNotification}
              disabled={deleteLoading}
            >
              <i className="fa fa-trash" />
            </button>
          </form>
        </div>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={onClickViewNotification}>
          <div className="pull-left">
            <img
              src={(systemGenerated && bot) || gravatarUrl}
              alt=""
              className="img-rounded mr-3 w-16 h-16"
            />
          </div>
          <div>
            <h5 className="mt-0">
              <u className={textClasses}>
                {(systemGenerated && 'Mr. Process-Bot') ||
                  displayName ||
                  'Unknown'}
              </u>
              <small className="text-muted ml-2">
                {isImportant && (
                  <i className="fa fa-exclamation text-danger mr-2" />
                )}
                {moment(+moment.utc(createdAt)).format('MM/DD/YYYY hh:mm A')}
              </small>
            </h5>
            <p id="message">
              <small>{message}</small>
            </p>
          </div>
          <div className="clear-left" />
        </a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
        <div className="clear-right" />
      </div>
    </li>
  )
}
