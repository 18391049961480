import asyncComponent from '../AsyncComponent'

export const ProductRequests = asyncComponent(() =>
  import(/* webpackChunkName: "ProductRequests" */ './ProductRequests')
)

export const Efforts = asyncComponent(() =>
  import(/* webpackChunkName: "Efforts" */ './Efforts')
)

export const ProjectProcessing = asyncComponent(() =>
  import(/* webpackChunkName: "ProjectProcessing" */ './ProjectProcessing')
)
